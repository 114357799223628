import React, { useEffect } from "react"

const Moteur = () => {
  useEffect(() => {
    var widget = document.getElementById("widget")
    const script = document.createElement("script")
    script.src = "https://widgets.skyscanner.net/widget-server/js/loader.js"
    script.defer = true
    widget.appendChild(script)
  })

  return (
    <div id="widget">
      <div
        data-skyscanner-widget="HotelSearchWidget"
        data-locale="fr-FR"
        data-market="fr"
        data-currency="€"
        data-affiliate="publisher:1614985;organizationId:1735668;network:TDBL;market:fr"
      ></div>
    </div>
  )
}

export default Moteur
